@import 'variables';

$gap: 16px;
$third-col-width: 350px;
$third-col-width-collapsed: 100px;
$collapse-animation-duration: 500ms;
$toggle-arrow-size: 32px;
$toggle-arrow-border-color: #f8f8f8;

.live-blog-editorial-admin-container {
	display: grid;
	grid-template-columns: 1fr 1fr $third-col-width;
	grid-template-rows: auto 1fr;
	grid-template-areas:
		'new-post feed misc'
		'title-edit feed misc';
	gap: $gap;

	&.two-columns {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			'title-edit feed'
			'new-post feed';
	}

	* {
		box-sizing: border-box;
	}

	> div {
		padding: $gap;
		border-radius: 16px;
		background-color: white;
		margin-bottom: 24px;
	}

	.live-blog-editorial-admin-column-header {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: $gap;
	}

	.live-blog-title-description-edit-container {
		display: flex;
		flex-direction: column;
		gap: 8px;

		button {
			margin-top: 8px;
			width: 100px;
		}
	}

	.live-blog-editorial-admin-post-feed-container {
		display: grid;
		grid-template-rows: auto 1fr;
	}

	.live-blog-editorial-admin-misc-container {
		position: relative;
		background-color: white;

		.match-widget-preview-container {
			width: calc(#{$third-col-width} - 2 * #{$gap});
			overflow: hidden;

			.live-blog-editorial-admin-misc-container-toggle {
				position: absolute;
				display: flex;
				align-items: center;
				justify-content: center;
				top: 34px;
				left: calc(-1 * #{$toggle-arrow-size} / 2);
				border-color: $toggle-arrow-border-color;
				border-radius: 50%;
				width: $toggle-arrow-size;
				height: $toggle-arrow-size;
				background-color: white;
				color: black;
				z-index: 1;
				box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5);
			}

			&.is-loading {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
			}

			.match-widget-preview-events-list,
			.widget-preview-wrapper.is-hidden {
				display: none;
			}
		}
	}

	&.collapsed-third-column {
		grid-template-columns: 1fr 1fr $third-col-width-collapsed;

		.live-blog-editorial-admin-misc-container {
			.live-blog-editorial-admin-misc-container-toggle > i {
				transform: rotate(180deg);
			}

			.match-widget-preview-container {
				width: 100%;

				.live-blog-editorial-admin-misc-container-toggle {
					visibility: hidden;
				}

				.widget-preview-wrapper {
					display: none;
				}

				.match-widget-preview-events-list {
					display: block;

					.match-widget-preview-event {
						display: flex;
						margin-bottom: 8px;
						border-radius: 6px;
						padding: 2px;
						background-color: $tabs-background-color;
						cursor: pointer;

						.match-widget-preview-event-team-logo {
							border-radius: 50%;
							width: 32px;
							height: 32px;
							background-size: cover;
						}
					}
				}
			}
		}
	}
}

.live-blog-editorial-admin-widget-modal {
	.copy-settings-row,
	[for^='copy-sports-connections-per-image'],
	[id^='copy-sports-connections-per-image'] {
		display: none;
	}
}

.live-blog-editorial-admin-error-fallback {
	color: $error;
}
