@import "../general/export.scss";

.custom-select-input-wrapper {
    min-width: 150px;
    width: 100%;
    display: inline-block;
    font-family: Arial, sans-serif;
    position: relative;

    &[aria-disabled="true"] {
      .custom-select-value-holder {
        background-color: #F5F5F5;
        cursor: not-allowed;
        opacity: 0.6;
      }
    }

  label {
    display: inline-block;
    font-size: 14px;
    color: #000;
    margin-bottom: 0;
  }

  .custom-select-value-holder {
    width: 100%;
    height: $lineHeight;
    line-height: $lineHeight;
    padding: 0 $elementsPadding;
    font-size: 14px;
    border-radius: $borderRadius;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    position: relative;
    cursor: pointer;

    &[data-placeholder="true"] {
      color: #a3a9b4;
    }

    .select-icon {
      position: absolute;
      right: $elementsPadding / 2;
      background: url('../../../../assets/icons/general/chevron-down.svg') no-repeat;
      width: 20px;
      height: 20px;
      background-position: center;
      top: 50%;
      transform: translateY(-50%);

      &.toggled-options {
        rotate: 180deg;
        transform: translateY(50%);
      }
    }
  }

  .custom-select-options {
    margin-top: $elementsMargin;
    width: 100%;
    font-size: 14px;
    border: 1px solid #BCC4D6;
    border-radius: $borderRadius;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    position: absolute;
    z-index: 2;

    .custom-select-option {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      transition: background-color 0.3s;
      padding: 0 16px;
      line-height: 36px;

      img {
        width: 24px;
        margin-right: 8px;
      }


      &:hover {
        background-color: #E7EFFF;
      }

      &:first-child {
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
      }

      &:last-child {
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
      }

    }
  }

  .info {
    display: inline-flex;
    align-items: center;

    .info-icon {
      background-image: url('../../../../assets/icons/general/info.svg');
      background-repeat: no-repeat;
      background-size: cover;
      width: 16px;
      height: 16px;
      cursor: pointer;
      margin-left: 6px;
      line-height: 20px;
      filter: invert(44%) sepia(97%) saturate(5732%) hue-rotate(214deg) brightness(99%) contrast(97%);
      margin-bottom: $elementsMargin;
    }

    .info-message {
      border-radius: 4px;
      background: #FFF;
      color: #000;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
      padding: 0 8px;
      max-width: 275px;
      position: absolute;
      z-index: 1;
      top: 22px;

      .info-message-title {
        display: flex;
        align-items: center;
        @include OpenSansFont(600, 20px, 14px);
        border-bottom: 1px solid #F0F0F0;
        min-height: 36px;
        padding: 8px 0;
      }

      .info-message-description {
        @include OpenSansFont(400, 20px, 12px);
        padding: 8px 0;
      }
    }
  }
}
