@import "../general/export.scss";

.custom-dynamic-select-input-wrapper {
    span {
      //display: none;
    }
}

.disclaimer_message_container {
  padding: 24px 16px 24px 16px;
  .domain_disclaimer_message {
    @include OpenSansFont(400, 20px, 14px);
    text-align: center;
  }
}

