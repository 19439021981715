@import '../../../../../global/fonts.scss';

$gap: 16px;

#custom-entity-wrapper {
    margin-bottom: 16px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 0px 50px 10px rgba(37, 40, 49, 0.06);
    display: flex;
    flex-direction: column;
    padding: $gap;
    width: 70%;

    h1 {
        color:  #000;
        @include OpenSansFont(700, 24px, 16px);
        margin-bottom: $gap;
        padding-left: $gap / 4;
    }

    > span {
        color: #000;
        @include OpenSansFont();
        margin-bottom: $gap;
        padding-left: $gap / 4;
    }

    label, #custom-entity-uploads-container > span {
        color: #0F172A;
        @include OpenSansFont(600, 20px, 14px);
    }

    .col, .col-md-6 {
        margin: $gap/2 0;

        .designed-button {
            color: #FFF;
            @include OpenSansFont(400, 20px, 12px);
        }
    }

    #custom-entity-uploads-container {
        margin-top: $gap / 2;
    }

    .custom-search-date-input {
        padding-right: 8px;
    }

    #custom-entity-translations-section, .custom-entity-role-entity-section{
        border-radius: 6px;
        background: #FAFAFA;
        padding: $gap/2;
        margin: $gap/2 (-$gap/2);

        &.custom-entity-role-entity-section {
            &:not(:last-child) {
                margin-bottom: $gap;
            }

            .remove-role-icon {
                display: flex;
                justify-content: flex-end;

                > div {
                    width: $gap * 2;
                    height: $gap * 2;
                    background-image: url('../../../../../assets/icons/general/delete-icon.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    position: relative;
                    right: $gap / 2;
                    cursor: pointer;
                }
            }

            .custom-dynamic-select-input-wrapper {
                label {
                    margin-bottom: 4px;
                }
            }
        }
    }

    #custom-entity-add-new-role-button {
        width: 100%;
        border-radius: 6px;
        border: 1px dashed #BFBFBF;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: $gap/2 auto $gap;
        background-color: unset;

        span {
            @include OpenSansFont(400, 20px, 12px);
            margin: unset;
            padding: unset;
            color: #000;
        }

        .add-role-icon {
            width: 16px;
            height: 16px;
            background-image: url('../../../../../assets/icons/general/plus-circle-active.svg');
            margin-right: $gap/2;
        }
    }

    .react-dropzone-custom-class {
        margin-top: 5px;
    }

    .custom-search-date-wrapper {
        label {
            margin-bottom: 5px;
        }

        .custom-search-date-input-wrapper {
            height: 36px;
        }

        .custom-search-date-input-left-arrow, .custom-search-date-input-right-arrow {
            display: none;
        }

        input {
            width: 100%;
            border-radius: 6px;
        }
    }

    .custom-dynamic-select-input-wrapper {
        svg {
            color: #666666
        }
    }

    .designed-button.rounded.blue {
        background-color: #0790E1;
        max-width: unset;

        &::before {
            content: url('../../../../../assets/icons/general/save.svg');
            position: relative;
            top: 3px;
            margin-right: 8px;
        }
    }

  .message_label_disclaimer_container {
    margin-top: -5px;
  }

  .contained_in_place_disclaimer {
    @include OpenSansFont(400, 18px, 11px);
    text-align: left;
  }
}

.disclaimer_message_container {
  display: grid;
  grid-template-rows: auto 1fr;
  place-items: center;
}

.domain_disclaimer_message {
  @include OpenSansFont(400, 20px, 14px);
  color: #000000;
  text-align: center;
}


.info-icon {
  background-image: url('../../../../../assets/icons/general/info.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px;
  height: 20px;
  filter: invert(44%) sepia(97%) saturate(5732%) hue-rotate(214deg) brightness(99%) contrast(97%);
}

@media screen and (max-width: 768px) {
    #custom-entity-wrapper {
        width: 100%;
    }
}
